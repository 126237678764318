import { createGlobalStyle } from "styled-components";


export const GlobaStyle = createGlobalStyle`
    * {
        font-family: 'Roboto', sans-serif;
        margin: 0;
        padding: 0;
        box-sizing: border-box;

        a {
            text-decoration: none;
        }

        
    }
`

