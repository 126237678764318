import * as React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <NavBG>
    <Layout>
      <Seo title="404: Not found" />
      
      <FourOFour>
      <h1>404</h1>
      </FourOFour>
    </Layout>
  </NavBG>
)

export default NotFoundPage

const FourOFour = styled.div`
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 6rem;
  color: #fabf49;
  
`

const NavBG = styled.div`
  background: #1b2227;
`