import * as React from "react"
import { GlobaStyle } from "./GlobalStyles"
import Nav2 from "./Layout Components/Nav2"
import Footer from "./Layout Components/Footer"


const Layout = ({ children }) => {
  

  return (
    <>
      <GlobaStyle />
      <Nav2 />
      
        <main>{children}</main>

        
        <section id='s8'><Footer /></section>  
    </>
  )
}



export default Layout
